<template>  
  <section id="Exclusao" >  
    <div class="cabecalho d-flex justify-content-between">
      <Breadcrumb/>
    </div> 

    <div class="jumbotron">  
      <div class="container-calculo">
          <Stepper class="stepper" :steps="this.steps" :currentStep="passo" @actualStep="passo=$event" :availableStep="this.availableStep"/>

        <div v-if="passo == 1" class="uploadComponentICMSExclusao"> <!--v-if="passo == 1"--> 
          <UploadComponentICMSExclusao @validateSpedPairing="validateSpedsPairment()" @blockStepper="availableStep = 0" :warnMessages="this.warnMessages" class="uploadComponentICMSExclusao"/>
        </div>

        <div v-if="passo == 2" class="resultICMSExclusao">
          <b-overlay :show="loadingSpinner" rounded opacity="0.5" spinner-small spinner-variant="secondary" style="padding: 1px 0px;">
            
            <div class="export-container">
              <div class="export-card">
                  <div>Exportar</div>
                  <div v-if="this.totalPisCofins > 0" class="export-card-buttons d-flex justify-content-center">
                    <button class="btn btn-outline-blue m-3" :disabled="isDisabledAll" @click="ExportarMensal()" download>Mensal .XLSX</button>   
                    <button class="btn btn-outline-blue m-3" :disabled="isDisabledAll" @click="ExportarAnual()" download>Anual .XLSX</button>
                    <button class="btn btn-outline-blue m-3" :disabled="isDisabledAll" @click="ExportarPDF()" download>Anual .PDF</button>
                  </div>
                  <div v-if="this.totalPisCofins <= 0" class="export-card-buttons d-flex justify-content-center">
                    <button class="btn btn-outline-blue m-3" :disabled="isDisabledAll" @click.prevent="updateResultExclusao($event)">Calcular</button>   
                  </div>
              </div>
            </div>

            <div class="resume-container">
              <b-card class="resume-list" no-body>
                <b-button class="upload-list-header" block v-b-toggle.accordion-1 variant="light">
                  <div>Resumo</div>
                  <div class="upload-list-header-buttons">
                    <div class="upload-list-count">
                      {{this.totalPisCofins > 0 ? "Créditos a Recuperar: " + formatMoneyPtBR(this.totalPisCofins) : "Atualize o cálculo"}}
                    </div>
                    <button @click.prevent="updateResultExclusao($event)"><Icon :iconName='"arrow-clockwise"' class="nav-icon" /></button>
                    <button><Icon :iconName='"chevron-down"' class="nav-icon" /></button>
                  </div>
                </b-button>
                  
                <b-collapse v-if="this.resultExclusao.length > 0" class="upload-list-collapse resume-table" id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th><strong>Período</strong></th>
                        <th><strong>ICMS Destacado</strong></th>
                        <th><strong>Crédito de Pis</strong></th>
                        <th><strong>Crédito de Cofins</strong></th>
                        <th><strong>Crédito Total</strong></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(period, index) in this.resultExclusao" :key="index">
                        <td>
                            {{ period.data_Inicial.split('-')[0]+"/"+period.data_Inicial.split('-')[1] }}
                        </td>

                        <td>
                            {{ formatMoneyPtBR(period.valor_Icms) }}
                        </td>                        

                        <td>
                            {{ formatMoneyPtBR(period.valor_Pis) }}
                        </td>

                        <td>
                            {{ formatMoneyPtBR(period.valor_Cofins) }}
                        </td>

                        <td>
                            {{ formatMoneyPtBR(period.valor_PisCofins) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-collapse>
              </b-card>            
            </div>
          </b-overlay>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

import emitter from "@/services/emitter";
import ExclusaoService from "@/services/teses/exclusao.service";
import UploadComponentICMSExclusao from "@/Components/UploadComponent/UploadComponentICMSExclusao.vue";
import Breadcrumb from "@/Components/Breadcrumb/Breadcrumb.vue";
import Stepper from "@/Components/Stepper/Stepper.vue";
import RelatorioService from "@/services/relatorio.service";
import HelperService from "@/services/helper.service.js";
import Icon from '@/Components/Icon/icon.vue';
import internal from "stream";

export default {
  name: "Exclusao",

  components: {
      Breadcrumb,
      Stepper,
      UploadComponentICMSExclusao,
      Icon,
      HelperService
  },
  data(){
    return{
        passo: 1,
        availableStep: 1,
        loadingSpinner: false,
        hasFiles: "",
        nCodOp: "",
        hasContent: false,
        dot: ".",
        steps: [
                {
                    stepNumber: 1,
                    stepTitle: "Enviar Arquivos",
                },
                {
                    stepNumber: 2,
                    stepTitle: "Resultado",
                },
            ],
        concat: null,
        dataCompleta: null,
        nomeCliente: "",
        isDisabled: false,
        isDisabledAll: false,
        nomeCliente: "",
        warnMessages: [],
        totalPisCofins: -1,
        resultExclusao: [],
    }
  },

  created(){
    this.nCodOp = this.$route.params.nCodOp;
  },
  async mounted(){
    await this.checkFilesExclusao();
    this.getCliente();
    this.getResultExclusao();
    this.validateSpedsPairment();
  },
  methods: {

    async validateSpedsPairment(){
      var spedsNotPaired = (await ExclusaoService.getSpedNotPaired(this.nCodOp)).data.dados;
      this.warnMessages = [];
      if(spedsNotPaired.length > 0){
        spedsNotPaired.forEach(element => {
          this.warnMessages.push("Período " + element.split('T')[0].split('-')[1] + '/' + element.split('T')[0].split('-')[0] + " não possui um par Sped Contribuições." )
        });
        this.availableStep = 1
      }
      else{
        this.availableStep = 2
      }
      console.log(this.warnMessages)
    },

    async checkFilesExclusao(){
      console.log("start checkFilesExclusao")
        await ExclusaoService.checkFilesExclusao(this.nCodOp)
            .then((resp) => {
                this.hasFiles = resp.data;
            })
            .catch((error) => {
                if(error.response.data != null) {
                    this.$swal({
                        icon: "error",
                        title: "Erro ao verificar arquivos!",
                        text: error.response.data.mensagem,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#3055d6",
                        confirmButtonText: "Ok",
                    })
                    .then(() => {
                        this.$router.push('/home');
                    })
                }
                else{
                    const msg = "Erro ao verificar arquivos";
                    const type = "error";
                    emitter.emit("makeModalDefault", {msg, type});
                }
            });
            console.log("end checkFilesExclusao")
    },

    loadPasso1(){
        this.passo = 1;
    },
    loadPasso2(){
        this.passo = 2;
    },
    formatMoneyPtBR(value){
      return HelperService.formatMoneyPtBR(value)
    },
    async updateResultExclusao(e){
      if(e != null && e != undefined) e.stopPropagation(),
      this.loadingSpinner = true,
      await ExclusaoService.updateResultExclusao(this.nCodOp)
      .then((response) => {
        if (response.status == 200) this.resultExclusao = response.data.dados
      })
      .catch((error) => {
        this.$toast.open({message: error, type: 'error', duration: 2000});
      });

      var newTotalPisCofins = 0;
      this.resultExclusao.forEach(element => {
        newTotalPisCofins = newTotalPisCofins + element.valor_PisCofins;
      });
      this.totalPisCofins = Math.round((newTotalPisCofins)*100)/100
      this.loadingSpinner = false
    },
    async getResultExclusao(){
      this.loadingSpinner = true,
      await ExclusaoService.getResultExclusao(this.nCodOp)
      .then((response) => {
        if (response.status == 200) {
          this.resultExclusao = response.data.dados;
          console.log(this.resultExclusao);
        }
      })
      .catch((error) => {
        this.$toast.open({message: error, type: 'error', duration: 2000});
      });
      
      var newTotalPisCofins = 0;
      this.resultExclusao.forEach(element => {
        newTotalPisCofins = newTotalPisCofins + element.valor_PisCofins;
      });
      this.totalPisCofins = Math.round((newTotalPisCofins)*100)/100
      this.loadingSpinner = false
    },
    dotAnimate(){
        setInterval(() => {
            setTimeout(() => {
                this.dot = ". ."
            }, 500)

            setTimeout(() => {
                this.dot = ". . ."
            }, 750)
        }, 1000)
        return this.dot
    },

    async ExportarMensal(){
      this.$toast.open({message: "Aguarde enquanto o relatório é processado.", type: 'info', duration: 4000});
      this.isDisabledAll = this.isDisabled = this.showOverlay = true;
      console.log(this.nCodOp);
      this.getCliente();
      await RelatorioService.ExportarMensalExclusao(this.nCodOp)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        this.MontaData();       
        this.concat = "Relatório Mensal_" + this.nomeCliente + "-" + this.dataCompleta;
        link.setAttribute("download", this.concat + ".xlsx");
        document.body.appendChild(link);
        link.click();
      });
      this.isDisabledAll = this.isDisabled = this.showOverlay = false;
      this.$toast.open({message: "Processamento concluído!", type: 'success', duration: 4000});
    },

    async getCliente() {
      await ExclusaoService.getCliente(this.nCodOp)
        .then((response) => {
          if (response.status == 200) {
            this.nomeCliente = response.data.dados;
            console.log("response.data.dados -->",response.data.dados)
            console.log("Nome Cliente -->",this.nomeCliente)
          }
        })
        .catch((error) => {
          this.$toast.open({message: error, type: 'error', duration: 2000});
        });
    },  

    async ExportarAnual(){
      this.$toast.open({message: "Aguarde enquanto o relatório é processado.", type: 'info', duration: 4000});
      console.log(this.nCodOp);
      await RelatorioService.ExportarAnualExclusao(this.nCodOp)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        this.MontaData();
        this.concat = "Relatório Anual_" + this.nomeCliente + "-" + this.dataCompleta;
        link.setAttribute("download", this.concat + ".xlsx");
        document.body.appendChild(link);
        link.click();
      });
      this.$toast.open({message: "Processamento concluído!", type: 'success', duration: 4000});
    },

    async ExportarPDF(){
      this.$toast.open({message: "Aguarde enquanto o relatório é processado.", type: 'info', duration: 4000});
      console.log(this.nCodOp);
      await RelatorioService.ExportarAnualPDF(this.nCodOp)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type:
            "application/pdf",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        this.MontaData();
        this.concat = "Relatório Anual_" + this.nomeCliente + "-" + this.dataCompleta;
        link.setAttribute("download", this.concat + "..pdf");
        document.body.appendChild(link);
        link.click();
      });
      this.$toast.open({message: "Processamento concluído!", type: 'success', duration: 4000});
    },

    MontaData(){
      var dataAtual = new Date;
      this.dia = dataAtual.getDate();
      this.mes = dataAtual.getMonth() + 1;
      this.ano = dataAtual.getFullYear();
      this.hora = dataAtual.getHours();
      this.minuto = dataAtual.getMinutes();
      this.segundo = dataAtual.getSeconds();
      this.dataCompleta = this.dia + "/" + this.mes + "/" + this.ano + "/" + "_" + this.hora + ":" + this.minuto + ":" + this.segundo;
    },

  },
};
</script>

<style>
#Teses{
    min-height: calc(100vh - 130px);
}
.resume-container{
  display: flex;
  justify-content: space-around;
  margin: 50px 0px;
}
.resume-list{
    width: 80%;
    margin: 20px;
    border: none;
    box-shadow:  18px 18px 36px #bebebe,
             -18px -18px 36px #ffffff;
    border-radius: 10px;
    display: flex;
    justify-items: center;
}
.resume-table{
  padding: 0px 20px;
}
.upload-list-header{
    background-color: white;
    color: black;
    border: none;
    border-radius: 10px;
    border-bottom: #233873;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    padding-left: 30px;
}
.upload-list-header-buttons button{
    background: none;
    border: none;
}

.upload-list-header div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.upload-list-count{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding: 15px;
    letter-spacing: -0.02em;

    color: #8E8E8E;
}

.container-calculo{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.uploadComponentICMSExclusao, .resultICMSExclusao{
  width: 100% !important;
  justify-content: center;
  align-content: center;
}

.export-container{
display: flex;
justify-content: space-around;
margin: 50px 0px;
}

.export-card{
    background-color: white;
    color: black;
    border-radius: 10px;
    min-height: 200px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);

    padding-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
}

  .tese{
      flex: 1 0 15%;
      display: flex;
      flex-direction: column-reverse;

      height: 22vh;
      margin: 2%;

      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
      border-radius: 5px;
      cursor: pointer;

      font-size: 28px;
      font-weight: 700;
      color: white;
      text-align: center;
  }

  .btn-acessar{
      transition: 0.5s;

      background: white;
      padding: 6px 0px;
      border-radius: 0px 0px 5px 5px;
      width: 100%;

      font-size: 18px;
      font-weight: 400px;
      color: gray;
  }

  .tese-green{
      transition: 0.5s;
      background: rgba(43, 182, 115, 0.85);
  }
  .tese-green:hover{
      font-size: 30px;
      background: #2BB673;
  }
  
  .tese-blue{
      transition: 0.5s;
      background: rgba(48, 48, 132, 0.85);
  }
  .tese-blue:hover{
      font-size: 30px;
      background: #303086;
  }

  .tese-gray{
      transition: 0.5s;
      background: #e3e3e3;
  }
  .tese-gray:hover{
      background: #ffc4ca;
  }
  .tese-gray:hover .btn-acessar{
      
      color: #E82134;
  }
  .btn-exportar{
    margin: 50px 30px;
  }


</style>
import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class RelatorioService {
  async Exportar(nCodOp, cnpj) {
    return axios.post(
      API_URL + "/Inss/exportar/",
      {
        nCodOp,
        cnpj,
      },
      {
        responseType: "blob",
      }
    );
  }

  async ExportarCompleto(nCodOp, nivel, cnpj) {
    return await axios.post(
      API_URL + "/Inss/exportarCompleto",
      {
        nCodOp,
        nivel,
        cnpj,
      },
      {
        responseType: "blob",
      }
    );
  }

  async ExportarCSV(nCodOp, cnpj) {
    console.log("export CSV service");
    return axios.post(
      API_URL + "/Inss/exportarCSV/",
      {
        nCodOp,
        cnpj,
      },
      {
        responseType: "blob",
      }
    );
  }

  // async ExportarMensalExclusao(NCodOp){
  //   return await axios.post(
  //     API_URL + "/IcmsExclusao/exportarXlsMensal",
  //     {
  //       NCodOp,
  //     },
  //     {
  //       responseType: "blob",
  //     }
  //   );
  // }

  async ExportarMensalExclusao(NCodOp) {
    console.log("NCodOp-->", NCodOp);
    return await axios.post(
      API_URL + "/IcmsExclusao/exportarXlsMensal",
      {
        NCodOp,
      },
      {
        responseType: "blob",
      }
    );
  }

  async ExportarAnualExclusao(NCodOp) {
    console.log("NCodOp-->", NCodOp);
    return await axios.post(
      API_URL + "/IcmsExclusao/exportarXlsAnual",
      {
        NCodOp,
      },
      {
        responseType: "blob",
      }
    );
  }

  async ExportarAnualPDF(NCodOp) {
    console.log("NCodOp-->", NCodOp);
    return await axios.post(
      API_URL + "/IcmsExclusao/exportarPDF",
      {
        NCodOp,
      },
      {
        responseType: "blob",
      }
    );
  }

  async SendToConvert(ListRelatorioAnaliseInicial, Tipo) {
    console.log("export CSV convert");
    return axios.post(
      API_URL + "/ConvertCsv/exportarCSV/",
      {
        ListRelatorioAnaliseInicial,
        Tipo
      },
      {
        responseType: "blob",
      }
    );
  }

}
export default new RelatorioService();
